import { LOAD_PRINTERS, SET_CATEGORY_PRINTER_ID } from './types';
import axios from 'axios';
import { expiredLogout, actionError } from './unAuthorised';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function updatePrinters(state){
    return [{
        type: LOAD_PRINTERS,
        payload: state
    }]
}

function updateCategoryPrinterId(printerChange){
    return [{
        type: SET_CATEGORY_PRINTER_ID,
        payload: printerChange
    }]
}

export function getPrinters(token){

    return function (dispatch){
        axios.defaults.headers.common['token'] = token;
        return axios.get(`${API_ENDPOINT}/iOrderPrintService/api/printers/getallprinters`,{

        }).then(res=>{
            const response = res.data;
            if (res.status === 200){
                // console.log(response);
                dispatch(updatePrinters(response));
            }
        }).catch(e=>{
            console.log("Get printers exception :" + e);
            dispatch(actionError(e,"Get Printers"));
        })
    }
}

export function setCategoryPrinterId(token, printerChange){

    return function (dispatch){
        axios.defaults.headers.common['token'] = token;
        return axios.put(`${API_ENDPOINT}/iorderws/api/itillcategories/changeiorderprinterid`,
        printerChange
        ).then(res=>{
            if (res.status === 200){
                dispatch(updateCategoryPrinterId(printerChange));       // API doesn't return the amended object
            }
        }).catch(e=>{
            if (e.response.status === 401) {
                console.log("Response error 401");
                dispatch(expiredLogout())
            }
            dispatch(actionError(e,"Set category printerID"))
        })
    }
}


