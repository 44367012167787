    // Clears browser's window.caches, cookies, Local Storage, Session Storage. Does not clear Service Worker cache.
    export const clearAll = async () => {
        console.log("Clearing storage for iOrder...");

        try {
            if (window.caches) {
                const cacheNames = await caches.keys();
                for (const cacheName of cacheNames) {
                    await caches.delete(cacheName);
                    console.log(`Deleted cache: ${cacheName}`);
                }
            }

            if (document.cookie) {
                document.cookie.split(";").forEach((cookie) => {
                    const name = cookie.split("=")[0].trim();
                    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                });
                console.log("Cookies cleared");
            }

            if (window.localStorage) {
                localStorage.clear();
                console.log("Local Storage cleared");
            }

            if (window.sessionStorage) {
                sessionStorage.clear();
                console.log("Session Storage cleared");
            }
            console.log("Storage cleared successfully.");
        } catch (error) {
            console.error("Error clearing storage:", error);
        }

    }