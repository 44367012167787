import React, { Component } from 'react';
import ReactTimeout from 'react-timeout'

import BlockUi from 'react-block-ui';
import { bindActionCreators } from 'redux';

import { messageShow } from './actions/messageActions'
import { cancelNav } from './actions/navActions'
import Login from './components/auth/Login';
import MainMenu from './components/menu/MainMenu';
import MessageDisplay from './components/extras/MessageDisplay'
import UserList from "./components/users/UserList";
import UserEdit from "./components/users/UserEdit";
import UserAdd from "./components/users/UserAdd"; 
import OrdersHistory from "./components/orders/OrdersHistory";
import TablesList from "./components/tables/TablesList";
import TableEdit from "./components/tables/TableEdit";
import Settings from "./components/config/Settings";



import './resources/css/Styles.css';
import './resources/css/App.css';
import './resources/css/bootstrap.css';
import './resources/css/primeflex.css';
import 'react-block-ui/style.css';
import 'typeface-roboto';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


import { connect } from 'react-redux';
import ProductBill from './components/products/ProductBill';



class App extends Component {


  state = {
    blocking: false,
    search: '',
    games: [],
    email: '',
    password: '',
    user: {},
    online: true,
    searching: false,
    tableFilterStatus: null,
    tableFilterSeats: null,
  };



  doSearch = (search) => {
    this.setState({ search })
  }

  doLogout = () => { this.setState({ user: {} }) }



  currentPage = () => {


    if (!this.props.user.token) {
      return (<Login />)
    } else {

      if (this.props.nav === "users") {
        return <UserList search={this.state.search} />
      }

      if (this.props.nav === "userAdd") {
        return <UserAdd />
      }

      if (this.props.nav === "userEdit") {
        return <UserEdit />
      }

      if (this.props.nav === "tablesList") {
        return <TablesList />
      }

      if (this.props.nav === "ordersHistory") {
        return <OrdersHistory />
      }

      if (this.props.nav === "editTable") {
        return <TableEdit />
      }

      if (this.props.nav === "productBill") {
        return <ProductBill />
      }

      if (this.props.nav === "settings") {
        return <Settings />
      }

    }
  }


  doCancelNav = () => {
    if (this.props.loading) {
      console.log("redirecting after timeout")
      this.props.cancelNav();
    }
  }


  render() {

    if (this.props.loading) {
      // console.log("setting timeout")
      this.props.setTimeout(this.doCancelNav, 160000)
    } else {
      // console.log("cancel timeout")
      this.props.clearTimeout();
    }


    return (
      <BlockUi tag="div" blocking={this.props.loading}>
        <MainMenu />
        <MessageDisplay />
        {this.currentPage()}
      </BlockUi>
    );
  }
}



function mapStateToProps(state) {
  return {
    message: state.message,
    user: state.user,
    loading: state.loading,
    nav: state.nav
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    messageShow: messageShow,
    cancelNav: cancelNav
  }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(ReactTimeout(App));

