import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/userActions'
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { clearAll } from '../../resources/javascript/Clear';

class Login extends Component {

    state = {
        pin: ''
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    doLogin = (e) => {
        e.preventDefault();
        clearAll();
        this.props.loginUser(this.state.pin);
    }

    addCharacter = (e) => {
        this.setState({ pin: this.state.pin + e })
    }

    componentDidMount() {
        this.props.loading(false);
    }

    render() {
        return (
            <div>
                <div className="box">
                    <br />
                    <div className="logo"/><br />
                    <Button variant="contained" onClick={() => this.addCharacter(1)} className="numpadButton"   >1</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(2)} className="numpadButton" >2</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(3)} className="numpadButton" >3</Button>
                    <br />
                    <Button variant="contained" onClick={() => this.addCharacter(4)} className="numpadButton" >4</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(5)} className="numpadButton" >5</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(6)} className="numpadButton" >6</Button>
                    <br />
                    <Button variant="contained" onClick={() => this.addCharacter(7)} className="numpadButton" >7</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(8)} className="numpadButton" >8</Button>
                    <Button variant="contained" onClick={() => this.addCharacter(9)} className="numpadButton" >9</Button>
                    <br />
                    <Button variant="contained" onClick={() => this.addCharacter(0)} className="numpadButton" style={{ marginLeft: '63px' }}> 0</Button>
                    <Button variant="contained" onClick={() => this.setState({ pin: this.state.pin.substring(0, this.state.pin.length - 1) })} className="numpadButton" >DEL</Button>
                    <form onSubmit={this.doLogin} >
                        <input
                            required
                            type="password"
                            name="password"
                            placeholder="Pin"
                            id="pinInput"
                            value={this.state.pin}
                            onChange={(e) => this.setState({ pin: e.target.value })}
                            style={{ width: 200, margin: 10 }}
                            
                        />


                        <br />

                        <Button variant="contained" style={{ width: 200, margin: 10 }} type="submit"  >Login</Button><br />
                    </form>
                </div>


            </div >
        )
    }
}

function mapStateToProps(state) {
    return { message: state.user.message }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loginUser: loginUser,
        loading: loading,
        changeNav: changeNav
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);
