import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reduxMulti from 'redux-multi';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import combineReducers from './reducers';

const persistConfig = {
    key: 'root',
    storage,
}


const persistedReducer = persistReducer(persistConfig, combineReducers)

let store = createStore(persistedReducer,
    composeWithDevTools(
        applyMiddleware(reduxThunk,
            reduxMulti),
        //  window.devToolsExtension ? window.devToolsExtension() : f => f
    )

)
let persistor = persistStore(store)



const renderApp = () => ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    , document.getElementById('root'));



renderApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
window.addEventListener('load', () => {
    registerSw();
})

async function registerSw() {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register("serviceWorker.js");
        } catch (e) {
            console.log('SW registration failed');
        }
    }
}