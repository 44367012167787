import { LOAD_LAST_ORDERS } from './types';
import axios from 'axios';
import { expiredLogout, actionError } from './unAuthorised';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function updateLast10Orders(state) {
    return [{
        type: LOAD_LAST_ORDERS,
        payload: state,
    }
    ]

}

export function getLast10Orders(token) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/bills/get_last_10_orders`, {
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateLast10Orders(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("get_last_10_orders exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "load orders"))
            })
    }
}
