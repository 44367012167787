import { LOAD_PRINTERS } from "../actions/types";


export default function(state=[], action){
 switch (action.type){
    case LOAD_PRINTERS:
        const printersArray = Object.entries(action.payload).map(([id, name]) => ({ id, name }));       // 'printers' comes in as an object.
        return printersArray;
    default: return state
 }
}

