import { LOAD_CATEGORIES, SET_CATEGORY_PRINTER_ID } from '../actions/types';


export default function (state = [], action) {

    switch (action.type) {
        case SET_CATEGORY_PRINTER_ID:
            const { id, iorderPrinterID } = action.payload;
            const newState =  state.map(category => {
                if (category.id === id) {
                    return { ...category, iorderPrinterID };
                }
                return category;
            });
            return newState;
        case LOAD_CATEGORIES:
            return action.payload;
        default: return state;
    }
}
