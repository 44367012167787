import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { loadCategories } from '../../actions/categoryActions'
import { getPrinters, setCategoryPrinterId } from '../../actions/printerActions'
import { loadProducts } from "../../actions/categoryActions";
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';


class Settings extends Component {

    state = {
        showProducts: false,
        selectedCategory: {},
        products: [],
    }

    componentDidMount() {
        this.props.loading(false);
        this.props.loadCategories(this.props.user.token);
        this.props.getPrinters();
    }

    showPrinters = () => {
        const printers = this.props.printers;
        return (
              printers.map((printer, i) => {
                return (
                    <div key={i} style={{ "display": "flex", "alignItems": "center", "marginBottom": "5px" }}>
                        <span>Printer <b>{printer.id}</b>:  {printer.name} </span>
                    </div>
                )
            })
        )
    }

    handlePrinterChange=(e, cat)=>{
        this.props.setCategoryPrinterId(this.props.user.token, {
                "id": cat.id, 
                "iorderPrinterID": e.target.value
            });
    }

    showCategories=()=>{
        const printers = [...this.props.printers];
        printers.push({id:-1, name: 'DO NOT PRINT!!!'})
        const padval=(v)=>v<0?v :`\u2002${v}`
        return (
            this.props.categories.map((cat,i) => {
            return (
                <div key={i} style={{ "display": "flex", "alignItems": "center", "marginBottom": "5px" }}>
                    <Button onClick={() => this.openProductList(cat)} className={`buttonHideText`} style={{ "backgroundColor": "#" + cat.background, "margin": 1, "width": 180, "padding": 2, "height": 50, "color": "#" + cat.foreground }}>
                        {cat.categoryName}
                    </Button>
                    <select value={cat.iorderPrinterID} onChange={(e) => this.handlePrinterChange(e,cat)} style={{ "margin": 1, "width": 'auto', "height": 50 }}>
                    {printers.map((printer, index) => (
                        <option key={index} value={printer.id}>{padval(printer.id)} : {printer.name}</option>
                    ))}
                    </select>
                </div>
 
            );
        })
        )
    }

    openProductList = (cat) => {     
        this.setState({ showProducts: true });
        this.setState({ selectedCategory: cat });
        
        this.props.loadProducts(this.props.user.token, cat.id);
    }

    closeProductList = () => {
        this.setState({ showProducts: false });
        this.setState({ selectedCategory: {} });
    }

    sideBarProducts = (cat) => {
        return (
            <React.Fragment >
                <Sidebar visible={this.state.showProducts} baseZIndex={1000000} onHide={(e) => this.setState({ showProducts: false })} style={{ "height": "100%" }} > 

                    <h1 style={{ fontWeight: 'normal' }}>{this.state.selectedCategory.categoryName}</h1>
                    <div style={{ "overflow": "scroll", "height": "625px" }}>      
                    {this.props.products.map((product,i) => {
                        var productPrice;
                        if (product.retailPrice > 0) {
                            productPrice = " - £" + product.retailPrice.toFixed(2);
                        }
                        return <div key={i} className="p-col1" style={{ "textAlign": "center" }}  >

                            <Button className="buttonHideText" style={{"pointerEvents": "none", "cursor":"default", "backgroundColor": "#" + product.background, "margin": 5, "width": 200, "height": 50, "color": "#" + product.foreground }} >
                                {product.name}{productPrice}
                            </Button>
                        </div>
                    })}  </div>
                    <Button type="button" onClick={(e) => this.closeProductList()} label="Return" className="p-button-success" style={{ "margin": 'auto', "display": "block", "marginTop": "10px" }} />

                </Sidebar>
            </React.Fragment>

        )

    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <h1 style={{ "margin": "auto", "color": '#3f51b5', "textAlign": "center", "marginTop": "20px" }}>Settings</h1>
                <h3 style={{ "margin": "auto", "color": '#3f51b5', "textAlign": "left", "marginTop": "20px", "marginBottom": "10px" }}>Available Printers</h3>
                { this.showPrinters() }<br/> 
                <h3 style={{ "margin": "auto", "color": '#3f51b5', "textAlign": "left", "marginTop": "20px", "marginBottom": "10px" }}>Set Printer by category</h3>
                { this.showCategories() }
                {this.sideBarProducts()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { 
        user: state.user,
        categories: state.categories,
        printers: state.printers,
        products: state.products,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loadCategories: loadCategories,
        getPrinters: getPrinters,
        setCategoryPrinterId: setCategoryPrinterId,
        loading: loading,
        changeNav: changeNav,
        loadProducts: loadProducts,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(Settings);