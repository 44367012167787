import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/userActions'
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import TableSearch from '../tables/TableSearch'
import { loadTables } from '../../actions/tableActions';
import { clearAll } from '../../resources/javascript/Clear';


const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },

    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'block',
        position: 'absolute'

    },
});



class MainMenu extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        tableFilterSeats: null,
        tableFilterStatus: null
    };
   
    userMenu = () => {
        const admin = (this.props.user.role === 'ADMIN');
        if (admin) {
            return <MenuItem onClick={this.users}>Users</MenuItem>;
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.doSearch(this.state.search);

    }


    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    handleLogout = () => {
        clearAll();
        this.handleMenuClose();
        this.props.logoutUser();
    }

    scheduleLogoutAt4AM = () => {
        const now = new Date();
        const targetTime = new Date();

        targetTime.setHours(4, 0, 0, 0);  // 4 AM today

        if (now > targetTime) {
            targetTime.setDate(targetTime.getDate() + 1);
        }
        console.log("Auto logout set to " + targetTime);
        const timeUntil4AM = targetTime - now;

        setTimeout(() => {
            this.handleLogout();
            this.scheduleLogoutAt4AM();
        }, timeUntil4AM);
    }

    componentDidMount() {
        this.scheduleLogoutAt4AM();
    }


    // manufactures = () => {
    //     if (this.props.nav !== 'manufactures') {
    //         this.props.changeNav('manufactures')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    // platforms = () => {
    //     if (this.props.nav !== 'platforms') {
    //         this.props.changeNav('platforms')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    // developers = () => {
    //     if (this.props.nav !== 'developers') {
    //         this.props.changeNav('developers')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    // publishers = () => {
    //     if (this.props.nav !== 'publishers') {
    //         this.props.changeNav('publishers')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    users = () => {
        if (this.props.nav !== 'users') {
            this.props.changeNav('users')
        }
        this.props.updateSearch('');
        this.handleMenuClose();
    }

    // genres = () => {
    //     if (this.props.nav !== 'genres') {
    //         this.props.changeNav('genres')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    // games = () => {
    //     if (this.props.nav !== 'games') {
    //         this.props.changeNav('games')
    //     }
    //     this.props.updateSearch('');
    //     this.handleMenuClose();
    // }

    tablesList = () => {
        if (this.props.nav !== 'tablesList') {
            this.props.changeNav('tablesList')
        }
        this.handleMenuClose();
    }

    ordersHistory = () => {
        if (this.props.nav !== 'ordersHistory') {
            this.props.changeNav('ordersHistory')
        }
        this.handleMenuClose();
    }

    settings = () => {
        if (this.props.nav !== 'settings'){
            this.props.changeNav('settings')
        }
        this.handleMenuClose();
    }

    refreshApp = () => {
        // this.props.loadTables(this.props.user.token);
        clearAll();
        this.handleMenuClose();
    }

    regions = () => {
        if (this.props.nav !== 'regions') {
            this.props.changeNav('regions')
        }
        this.props.updateSearch('');
        this.handleMenuClose();
    }


    render() {
        const { mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


        const renderTableSearch = () => {
            if (this.props.nav === 'tablesList') {
                return <React.Fragment><TableSearch tableFilterSeats={this.state.tableFilterSeats} updateTableFilterSeats={(tableFilterSeats) => this.doFilterTableSeats(tableFilterSeats)} tableFilterStatus={this.state.tableFilterStatus} updateTableFilterStatus={(tableFilterStatus) => this.doFilterTableStatus(tableFilterStatus)}   /></React.Fragment>
            }
            else {
                return <React.Fragment></React.Fragment>
            }

        }

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.tablesList}>Tables</MenuItem>
                <MenuItem onClick={this.ordersHistory}>Orders History</MenuItem>
                <MenuItem onClick={this.settings}>Settings</MenuItem>
                <MenuItem onClick={this.refreshApp}>Refresh View</MenuItem>
                {this.userMenu()}
                <MenuItem onClick={this.handleLogout}>Log out</MenuItem>
            </Menu>
        );


        const Greeting = () => {
            if (!this.props.user.token) {
                return <React.Fragment></React.Fragment>;
            }
            return <React.Fragment>

                <div className={classes.grow} />
                <div className={classes.sectionMobile}>
                    <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                        <MoreIcon />
                    </IconButton>
                </div>
            </React.Fragment>;

        }



        return (

            <div className={classes.root}>

                <AppBar position="static">
                    <Toolbar>
                        {Greeting()}
                     
                        <Typography variant="h6" color="inherit" noWrap>
                            iOrder
                        </Typography>
                    </Toolbar>
                 
                </AppBar>
                {renderMobileMenu}
            </div>
        );
    }
}

MainMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.user,
        nav: state.nav
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loadTables: loadTables,
        logoutUser: logoutUser,
        loading: loading,
        changeNav: changeNav
    }, dispatch)
}



export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(MainMenu));

