import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCategories } from "../../actions/categoryActions";
import { loadBill } from "../../actions/billActions";
import { loadProducts, unloadProducts } from "../../actions/categoryActions";
import { addProduct, deleteProduct, updateQuantity, updateProduct, submitBill, finaliseBill, updateMultipleQuantities, createMultipleBillItems } from "../../actions/billActions";
import { setSelectedItem } from "../../actions/selectedItemActions";
import { setSelectedTable } from "../../actions/selectedTableActions";
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Sidebar } from 'primereact/sidebar';
import { generateTableBillId, getTableBillId } from '../../actions/tableActions';
import { editTable } from '../../actions/tableActions';


class ProductBill extends Component {

    constructor(props) {
        super(props);
        this.throttledAddProductToBill = this.throttle(this.addProductToBill, 300);
    }


    state = {
        showProducts: false,
        closeSelectedProductSideBar: false,
        selectedBillProduct: [],
        selectedCategory: [],
        selectedTable: [],
        products: [],
        tableID: this.props.selectedTable.tableID,
        tableSeats: this.props.selectedTable.seats,
        tableStatus: this.props.selectedTable.statusID,
        showFinaliseMenu: false,
        showIncrementDialog: false,
        showDecrementDialog: false,
        showTransferSelectedItemsDialogue: false,
        showAddNoteMenu: false,
        billNote: '',
        billItems:0,
        currentTableBillId:null,
        tableToTransferItemsFrom:"",
        tableToTransferItemsTo:"",
        transferableBillItems:[],
        atLeastOneItemToBeTransferred:true,
        toggleQuantitiesZeroes: true,
    }

    throttle = (func, delay) => {
        let lastCall = 0;
        return (...args) => {
            const now = Date.now();
            if (now - lastCall >= delay) {
                lastCall = now;
                func.apply(this, args); // Ensure the correct context (this) is maintained
            }
        };
    };

    setTransferableBillItems=()=>{
        this.setState({transferableBillItems: this.props.bill})
    }

    selectCategory = (item) => {
        this.setState({ selectedCategory: item });
        this.setState({ showProducts: true });
        this.props.loadProducts(this.props.user.token, item.id);
    }


    toggleFinalisebillMenu = (item) => {
        this.setState({ showFinaliseMenu: item })
    }

    toggleIncrementDialog = (item) => {
        this.setState({ showIncrementDialog: item })
    }

    toggleDecrementDialog = (item) => {
        this.setState({ showDecrementDialog: item })
    }

    toggleTransferSelectedItemsDialogue = (item) => {
        this.setState({ showTransferSelectedItemsDialogue: item })
    }

    handleClickOpenTransferSelectedItemsDialogue = (table) => {
        this.toggleTransferSelectedItemsDialogue(true);
        this.setState({tableToTransferItemsFrom:table});
        this.setTransferableBillItems();    // Duplicates bill state
        this.setState({toggleQuantitiesZeroes: true});
    }

    handleCancelTransferSelectedItemsDialogue = ()=>{
        this.toggleTransferSelectedItemsDialogue(false);
        this.setState({tableToTransferItemsTo:""});
    }

    // Filters out key-value pairs that are not required.
    filterJsonArray = jsonArray => {
        const allowedKeys = ["billId", "productId", "quantity", "billItemId", "price"];      // "billId", I don't think "rollingBillId" should be included. It should be manually calculated.
        return jsonArray.map(originalJson => {
          const filteredJson = {};
          
          for (const key in originalJson) {
            if (allowedKeys.includes(key)) {
              filteredJson[key] = originalJson[key];
            }
          }
          return filteredJson;
        });
      }
      
    passValuesToBillActions =()=>{
        const itemsToTransfer = [...this.state.transferableBillItems];
        return this.filterJsonArray(itemsToTransfer);
    }

    calcQuantitiesTableTransferringFrom=(tableToItems)=>{
        let tableFromItems = [];
        let compare = this.props.bill;

        for (let i = 0; i < compare.length; i++) {
          const item1 = compare[i];
          const item2 = tableToItems[i];
      
          if (item1.billId !== item2.billId) {
            console.error(`Error: billId mismatch for ${item2.billId}`);
          } else {
            const remainder = { ...item2 }; 
            remainder.quantity = item1.quantity-item2.quantity;
            tableFromItems.push(remainder);
          }
        }
        return tableFromItems;
      }
 
    removeZeroQuantitiesAfterTransfer=async(multipleBillItemsForTableToTransferTo)=>{
        const updateQuantitiesTableTransferringFrom = this.calcQuantitiesTableTransferringFrom(multipleBillItemsForTableToTransferTo);
        await this.props.updateMultipleQuantities(this.props.user.token,this.state.tableToTransferItemsFrom,updateQuantitiesTableTransferringFrom);
        this.props.bill.forEach(item=>{
            if (item.quantity==0){ this.props.deleteProduct(this.props.user.token, item.billItemId, this.state.tableID); }
        })
    }

    handleProceedTransferSelectedItemsDialogue = ()=>{
        this.setState({showTransferSelectedItemsDialogue: false});   
        const multipleBillItemsForTableToTransferTo = this.passValuesToBillActions();
        const itemsWithoutZeroQuantities = multipleBillItemsForTableToTransferTo.filter(item=>item.quantity>0)
        this.props.createMultipleBillItems(this.props.user.token,this.state.tableToTransferItemsTo,itemsWithoutZeroQuantities);    
        this.removeZeroQuantitiesAfterTransfer(multipleBillItemsForTableToTransferTo);
    }

    handleToggleQuantities = () => {
        this.state.toggleQuantitiesZeroes?this.setToZeroes():this.setToOriginalValues();
        this.setState((prevState)=>({
            toggleQuantitiesZeroes: !prevState.toggleQuantitiesZeroes,
        }))
        
    }

    setToZeroes=async()=>{
        const currentState = [...this.state.transferableBillItems];
            for (let i=0; i<currentState.length; i++){
            const updatedObject = { ...currentState[i], quantity: 0 };
            currentState[i] = updatedObject;
        };
        await this.setState({transferableBillItems: currentState});
        this.atLeastOneItemToBeTransferredFn();  // async to account for lag in updating state.
    }

    setToOriginalValues=async()=>{
        const currentState = [...this.state.transferableBillItems];
        for (let i=0; i<currentState.length; i++){
            const index = this.props.bill.findIndex(item => item.billId === currentState[i].billId);
            const originalQuantity = this.props.bill[index].quantity;
            const updatedObject = { ...currentState[i], quantity: originalQuantity };
            currentState[i] = updatedObject;
        };
        await this.setState({transferableBillItems: currentState});
        this.atLeastOneItemToBeTransferredFn();  // async to account for lag in updating state.
    }

    getTableDetails=(tableID)=>{
        const table = this.props.tables.find(t=>t.tableID===tableID);
        return table;
    }

    toggleAddNoteMenu = (item) => {
        if(item){
            this.setState({billNote: this.state.selectedBillProduct.note})
        }
        this.setState({ showAddNoteMenu: item });
        this.setState({ showSelectedBillProduct: false });
    }

    finaliseBill = (item) => {
        this.props.loading(true);
        this.props.finaliseBill(this.props.user.token, item, this.state.tableID);
        this.setState({ showFinaliseMenu: false });
        this.props.changeNav('editTable');
    }

    selectProductInBill = (item) => {
        this.setState({ selectedBillProduct: item })
        this.setState({ showSelectedBillProduct: true });
    }

    determineTableBillId = async() => {
        let TableBillId1;
        
        if (!this.props.bill.length){
            TableBillId1 = await this.props.generateTableBillId(this.props.user.token);
            var currentTable = this.props.selectedTable;
            var occupiedStatusId = 3, adHocFlag=1;
            await this.props.editTable(this.props.user.token, currentTable.tableID, occupiedStatusId, currentTable.seats, currentTable.tempTable, adHocFlag);
            return TableBillId1.data;
        } else {
            return this.props.bill[0].tableBillId;
        }
    }

    addProductToBill = async(item) => {
        if (this.isProcessing) return; // Prevent concurrent execution. Specifically - prevent duplicate item being created with a different tableBillId.
        this.isProcessing = true;
    
        try {
            const matchingProductsArray = this.props.bill.filter(product => product.productId === item.productId); 
            // Note - Check later if handling status == 2 correctly.
            const existingProductNotPrinted = matchingProductsArray.find(product => this.productNotPrinted(product)); 
            let tableBillId;
            tableBillId = await this.determineTableBillId();
            if (existingProductNotPrinted) {
                // tableBillId = existingProductNotPrinted.tableBillId;
                this.changeQuantityInBill(1, existingProductNotPrinted);        // Add one to existing product if item already on bill.
            } else {
                tableBillId = await this.determineTableBillId();        // If no existing product, determine a new "tableBillId".
                const rollingBillId = this.props.selectedTable.rollingBillId;
                this.props.addProduct(this.props.user.token, tableBillId, item.productId, item.retailPrice, this.state.tableID, rollingBillId);
            }
            setTimeout(() => {
                if (this.quantityInput) {
                    this.quantityInput.select(); 
                }
            }, 300);
        } finally {
            this.isProcessing = false; // Release the lock.
        }
    }

    productNotPrinted = (item) =>{
        return (item.printerProcessedStatus !== 1 && item.printerProcessedStatus !== 2); // Note - Check later if handling status == 2 correctly.
    }

    submitBill = (item, itemsToPrint) => {
        this.props.submitBill(this.props.user.token, item, this.state.tableID, itemsToPrint);
    }

    deleteProductFromBill = (item) => {
        this.props.deleteProduct(this.props.user.token, item.billItemId, this.state.tableID);
        this.setState({ showSelectedBillProduct: false });
    }

    updateProductFromBill = (item) => {
        this.props.updateProduct(this.props.user.token, item, this.state.selectedBillProduct.billItemId, this.state.tableID);
        this.toggleAddNoteMenu(false);

    }

    allCategories = () => {
        return (<div className="p-grid" style={{ "textAlign": "center", "margin": "auto", "width": "100%" }}>
            {this.props.categories.map((category) => {

                return <div key={category.id} className="p-col1" style={{ "textAlign": "center" }}  >
                    <Button onClick={() => this.selectCategory(category)} label={category.categoryName} style={{ "backgroundColor": "#" + category.background, "margin": 5, "width": 200, "color": "#" + category.foreground }} />
                </div>

            })}
        </div>
        )


    }

    closeProductList = () => {
        this.setState({ showProducts: false });
        this.props.unloadProducts();
    }

    closeSelectedProductSideBar = () => {
        this.setState({ showSelectedBillProduct: false });
    }

    finaliseBillMenu = () => {
        const footer = (
            <div>
                <Button label="Finalise Bill" icon="pi pi-check" onClick={() => this.finaliseBill(this.props.bill)} />
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.toggleFinalisebillMenu(false)} className="p-button-secondary" />
            </div>
        );

        return (
            <Dialog closable={false} header="Please confirm to finalise the bill." visible={this.state.showFinaliseMenu} style={{ width: '50vw' }} footer={footer} onHide={() => this.toggleFinalisebillMenu(false)} >
                Once you finalise the bill you will need to go to a local iTill to process the payment.
            </Dialog>
        )
    }

    incrementDialog = () => {
        const footer = (
            <div>
                <Button label="Okay" icon="pi pi-times" onClick={() => this.toggleIncrementDialog(false)} className="p-button-secondary" />
            </div>
        );

        return (
            <Dialog closable={false} header="Can't add to previously printed item using this button" visible={this.state.showIncrementDialog} style={{ width: '50vw' }} footer={footer} onHide={() => this.toggleIncrementDialog(false)} >
                This item has been previously printed and you can't increase the quantity using this button. <br/><br/>
                To add another of this product, use the appropriate category above.
            </Dialog>
        )
    }

    DecrementDialog = () => {
        const footer = (
            <div>
                <Button label="Okay" icon="pi pi-times" onClick={() => this.toggleDecrementDialog(false)} className="p-button-secondary" />
            </div>
        );

        return (
            <Dialog closable={false} header="Can't reduce previously printed item using this button" visible={this.state.showDecrementDialog} style={{ width: '50vw' }} footer={footer} onHide={() => this.toggleIncrementDialog(false)} >
                This item has been previously printed and you can't reduce the quantity using this button.<br/><br/>
                <p style={{ fontWeight: '700', margin: '0' }} >Reduce quantity - alternative method</p>
                If you need to reduce the quantity, you can enter a new value in the quantity box by selecting 
                the existing value and overtyping a new value.<br/><br/>
                <p style={{ fontWeight: '700', margin: '0' }}>Remove Item</p>
                You can remove this item completely by selecting the existing value and overtyping it with a zero or you can remove the item by selecting 
                the product description box and clicking on the "Remove Product" button from the sidebar menu.
            </Dialog>
        )
    }

    transferSelectedItemsDialogue = () => {
        const dropDown = (
            <span style={{ marginRight: 10}}>
                <select
                    value={this.state.tableToTransferItemsTo}
                    onChange={(e) => { this.setState({ tableToTransferItemsTo: parseInt(e.target.value, 10) }) }}
                    style={{ minWidth: "220px"}}
                >
                    <option value="" disabled>Select Table/Tab</option>
                    {this.props.tables.map(table =>
                        this.state.tableToTransferItemsFrom !== table.tableID ?
                        <option key={table.tableID} value={table.tableID}>{table.rollingBillId?`Tab ⇒`:`Table ⇒`} {`${table.tempTable} (${table.tableID})`}</option> : ''
                    )}
                </select>
            </span>
        )
        let tableSelectedToTrfrTo = this.getTableDetails(this.state.tableToTransferItemsTo);
        const footer = (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {this.state.tableToTransferItemsTo!==""&&
              (<div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                 <span>
                    You are transferring items to: 
                    <span style={{fontSize:'1.2em'}}>{tableSelectedToTrfrTo.rollingBillId>1?'Tab':'Table'}{' '} 
                        <span style={{ color: 'red' }}>
                            {tableSelectedToTrfrTo.tempTable}
                        </span>{' '}
                    ({this.state.tableToTransferItemsTo})
                    </span>
                </span>
              </div>)}
              <div style={{ fontSize: '1.2em', marginLeft: 'auto'  }}>
                <Button style={{ fontSize: '1.1em', marginRight: '10px' }} label={`Toggle Quantities: ${this.state.toggleQuantitiesZeroes ? 'Zeroes' : 'Original'}`} icon="pi pi-check" onClick={() => this.handleToggleQuantities()} />
                Transfer items to: {dropDown}
                <Button style={{ fontSize: '1.1em', marginRight: '10px' }} label="Transfer Items" icon="pi pi-check" disabled={this.state.tableToTransferItemsTo === "" || !this.state.atLeastOneItemToBeTransferred} onClick={() => this.handleProceedTransferSelectedItemsDialogue()} />
                <Button style={{ fontSize: '1.1em' }} label="Cancel" icon="pi pi-times" onClick={this.handleCancelTransferSelectedItemsDialogue} className="p-button-secondary" />
              </div>
            </div>
          );
        const tableOrTab = this.props.selectedTable.rollingBillId>1?'Tab':'Table';
        const tableOrTabName = (
            <span style={{fontSize:'1.2em'}}>
                You are transferring items from: 
                <span style={{fontSize:'1.2em'}}>{tableOrTab}{' '} 
                    <span style={{ color: 'red' }}>
                        {this.props.selectedTable.tempTable}
                    </span>{' '}
                ({this.props.selectedTable.tableID})
                </span>
            </span>
        );

        const transferableBillItems = () => {
            let sortedBill = this.state.transferableBillItems.sort((a, b) => a.billId - b.billId); 
        
            return (  
                <div style={{ "textAlign": "center", "margin": "auto", "max-width": "1100px" }}>
                  <div className="button-container" style={{  "justifyContent": "center"}}>
                    <div style={{"display": "flex", "flexWrap": "wrap", "justifyContent": "left"}}>
                    {sortedBill.map((billItem) => {
                      let productPrice = " - £" + billItem.price.toFixed(2);
                      
                      return (
                        <div key={billItem.id} style={{ "display": "flex", "alignItems": "center", "marginBottom": "5px" }}>
                          <Button className={`buttonHideText`} style={{ "backgroundColor": "#" + billItem.background, "margin": 1, "width": 180, "padding": 2, "height": 50, "color": "#" + billItem.foreground }}>
                            {billItem.name}{productPrice}
                          </Button>
                          <button onClick={() => this.changeTransferQuantity(-1, billItem)} className='buttonHideText' style={{ "fontSize": "20px", "backgroundColor": "red", "margin": 1, "width": 35, "height": 50, "color": "#" + billItem.foreground }}>-</button>
                          <input
                            id = {billItem.billId}
                            type="number"
                            ref={(input) => { this.quantityInput = input; }}
                            value = {billItem.quantity}
                            maxLength={3} 
                            onChange={(e) => this.handleQuantityChange(e, billItem)}
                            style={{ "backgroundColor": "white", "margin": 1, "width": 40, "height": 50, "color": "black", 
                            "textAlign": "center", "appearance": "textfield", "webkitAppearance": "none", 
                            "-moz-appearance": "textfield", "moz-appearance": "textfield", }}
                          />
                          <button onClick={() => this.changeTransferQuantity(1, billItem)} className='buttonHideText' style={{ "fontSize": "20px", "backgroundColor": "green", "margin": 1, "width": 35, "height": 50, "color": "#" + billItem.foreground, "marginRight": "8px" }}>+</button>
                        </div>
                      );
                    })}
                    </div>
                  </div>
                </div>
            );
          }

        return (
            <Dialog closable={false} header={tableOrTabName} visible={this.state.showTransferSelectedItemsDialogue} style={{ width: '90vw' }} footer={footer} onHide={() => this.toggleTransferSelectedItemsDialogue(false)} >               
                <div style={{ maxHeight: '80vh', minHeight: '50vh', overflow: 'auto' }}>
                    {transferableBillItems()}

                </div>
            </Dialog>
        )
    }

    addNoteMenu = () => {
        const footer = (
            <div>
                <Button label="Save Note" icon="pi pi-check" onClick={() => this.updateProductFromBill(this.state.billNote)} />
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.toggleAddNoteMenu(false)} className="p-button-secondary" />
            </div>
        );

        return (


        <Dialog closable={false} header="Please confirm your note below" visible={this.state.showAddNoteMenu} style={{ width: '50vw' }} footer={footer} onHide={() => this.toggleAddNoteMenu(false)} >
            <input 
                name="note"
                placeholder="e.g. No Mayo"
                autoFocus={true}
                value={this.state.billNote}
                onChange={(e) => this.setState({billNote: e.target.value })}
                style={{ width: 200, margin: 10 }}
                
            />
        </Dialog>


        )
    }

    findProduct=(productId)=>{
        return this.props.products.find(v=> v.productId==productId);
    }

    changeQuantityInBill=(increment, item, button=null)=>{
        console.log("item", item);
        if (button!=null){
            if (button.classList.contains('disabled-look-for-button')){
                if (button.id.includes('increase')){
                    this.setState({ showIncrementDialog: true });
                } else if (button.id.includes('decrease')){
                    this.setState({ showDecrementDialog: true });
                }
                return;
            } 
        }
        
        let quantity = item.quantity + increment;
        if (quantity<=0){
            this.props.deleteProduct(this.props.user.token, item.billItemId, this.state.tableID);
            this.setState({ showSelectedBillProduct: false });
        } else {
            this.props.updateQuantity(this.props.user.token, item.productId, quantity, this.props.selectedTable.tableID, item.billId, item.billItemId);
        }
    }

    getMaxTransferQuantityForBillItem(billId){
        const billItem = this.props.bill.find(b=>b.billId==billId)
        return billItem.quantity;
    }

    updateQuantity = (itemId, newQuantity) => {
        const currentState = [...this.state.transferableBillItems];
        const index = currentState.findIndex(item => item.billId === itemId);
        if (index !== -1) {
          const updatedObject = { ...currentState[index], quantity: newQuantity };
          currentState[index] = updatedObject;
          this.setState({transferableBillItems: currentState});
        }
      };

    changeTransferQuantity=async (increment, item)=>{
        let maxQuantity = this.getMaxTransferQuantityForBillItem(item.billId);
        const qtyInputField = document.getElementById(item.billId);
        if (increment==1 && parseInt(qtyInputField.value, 10)+1 <= maxQuantity){
            await this.updateQuantity(item.billId, item.quantity+1);
        } else if (increment==-1 && parseInt(qtyInputField.value, 10)-1 >= 0){
            await this.updateQuantity(item.billId, item.quantity-1);
        }
        this.atLeastOneItemToBeTransferredFn();  // async to account for lag in updating state.
    }

    atLeastOneItemToBeTransferredFn=()=>{
        let bills = this.state.transferableBillItems;
        const found = bills.some(item => item.quantity > 0);
        this.setState({atLeastOneItemToBeTransferred: found});

    }

handleQuantityChange = (e, item) => {
    if (isNaN(e.target.value) || e.target.value === '') {
        return;
    }
    let newQuantity = parseInt(e.target.value, 10);
    if (newQuantity>=1000){newQuantity=parseInt(newQuantity.toString().substring(0, 3), 10)};
    if (newQuantity<=0){        //  || isNaN(newQuantity) if you add this and amount is deleted, item will be removed.
        this.props.deleteProduct(this.props.user.token, item.billItemId, this.state.tableID);
        this.setState({ showSelectedBillProduct: false });
    } else {
        this.props.updateQuantity(this.props.user.token, item.productId, newQuantity, this.props.selectedTable.tableID, item.billId, item.billItemId);
    }
  };

tableBill = () => {
    var productTotal = 0.00;
    let sortedBill = this.props.bill.sort((a, b) => a.billId - b.billId);        // Reinstate later.
    sortedBill.forEach((newBill) => {
        productTotal += newBill.quantity * newBill.price;
    });

    productTotal = productTotal.toFixed(2);

    return (
      <div>
        <h2 style={{ "width": "200", "margin": "auto", "textAlign": "center", "marginBottom": "2px", "marginTop": "20px" }}>
        
          Bill for 
          {this.props.selectedTable.rollingBillId>1?' Tab ':' Table '} {this.props.selectedTable.tempTable?(
            <span>
                {this.props.selectedTable.tableID}{': '}
                <span style={{ color: 'red' }}>
                    {this.props.selectedTable.tempTable}
                </span>
            </span>
        ):`(${this.props.selectedTable.tableID}) `}
           <span style={{ color: 'red' }}> £{productTotal}</span>
        </h2><br />
        <div style={{ "textAlign": "center", "margin": "auto", "fontWeight": "bold", "fontSize": "20px", "paddingBottom": '10px' }}>
        {this.props.bill.length > 0 && (
          <>
            <Button label="Print New Items" onClick={() => this.submitBill(this.props.bill, "active")} />&nbsp; 
            <Button label="Print All" onClick={() => this.submitBill(this.props.bill, "all")} />&nbsp;  
            <Button label="Finalise Bill" onClick={() => this.toggleFinalisebillMenu(true)} />&nbsp; 
            <Button label="Transfer Items" onClick={() => this.handleClickOpenTransferSelectedItemsDialogue(this.state.tableID)} />&nbsp; 
            </>
        )}

        </div>
        <div style={{ "textAlign": "center", "margin": "auto", "width": "100%", }}>
          <div className="button-container" style={{  "justifyContent": "center"}}>
            <div style={{"display": "flex", "flexWrap": "wrap", "justifyContent": "left"}}>
            {sortedBill.map((newBill,i) => {
              var productPrice;
              productTotal = (newBill.quantity * newBill.price) + productTotal;
              productPrice = " - £" + newBill.price.toFixed(2);
              
              return (
                
                <div key={newBill.id} style={{ "display": "flex", "alignItems": "center", "marginBottom": "5px" }}>
                  <Button onClick={() => this.selectProductInBill(newBill)} className={`buttonHideText ${newBill.processedStatus > 0 ? "disabled-look-for-button" : ""}`} style={{ "backgroundColor": "#" + newBill.background, "margin": 1, "width": 180, "padding": 2, "height": 50, "color": "#" + newBill.foreground }}>
                    {newBill.name}{productPrice}
                  </Button>
                  <button key={i} id={`decrease_${i}`} onClick={(event) => this.changeQuantityInBill(-1, newBill, event.target)} className={`buttonHideText ${!this.productNotPrinted(newBill)? "disabled-look-for-button" : ""}`} style={{ "fontSize": "20px", "backgroundColor": "red", "margin": 1, "width": 35, "height": 50, "color": "#" + newBill.foreground }}>-</button>
                  <input
                    type="number"
                    ref={(input) => { this.quantityInput = input; }}
                    value = {newBill.quantity != null ? newBill.quantity : 1}
                    maxLength={3} 
                    onChange={(e) => this.handleQuantityChange(e, newBill)}
                    style={{ "backgroundColor": "white", "margin": 1, "width": 40, "height": 50, "color": "black", 
                    "textAlign": "center", "appearance": "textfield", "webkitAppearance": "none", 
                    "-moz-appearance": "textfield", "moz-appearance": "textfield", }}
                  />
                  <button key={i} id={`increase_${i}`} onClick={(event) => this.changeQuantityInBill(1, newBill, event.target)} className={`buttonHideText ${!this.productNotPrinted(newBill)? "disabled-look-for-button" : ""}`} style={{ "fontSize": "20px", "backgroundColor": "green", "margin": 1, "width": 35, "height": 50, "color": "#" + newBill.foreground, "marginRight": "8px" }}>+</button>
                </div>
              );
            })}
            </div>
          </div>
        </div>

        
      </div>
    );
  }
    sideBarProducts = () => {
        return (
            <React.Fragment >
                <Sidebar visible={this.state.showProducts} baseZIndex={1000000} onHide={(e) => this.setState({ showProducts: false })} style={{ "height": "100%" }} > 

                    <h1 style={{ fontWeight: 'normal' }}>{this.state.selectedCategory.categoryName}</h1>
                    <div style={{ "overflow": "scroll", "height": "625px" }}>      {this.props.products.map((product) => {
                        var productPrice;
                        if (product.retailPrice > 0) {
                            productPrice = " - £" + product.retailPrice.toFixed(2);
                        }
                        return <div key={product.id} className="p-col1" style={{ "textAlign": "center" }}  >

                            <Button onClick={() => this.throttledAddProductToBill(product)} className="buttonHideText" style={{ "backgroundColor": "#" + product.background, "margin": 5, "width": 200, "height": 50, "color": "#" + product.foreground }} >
                                {product.name}{productPrice}
                            </Button>
                        </div>
                    })}  </div>
                    <Button type="button" onClick={(e) => this.closeProductList()} label="Return" className="p-button-success" style={{ "margin": 'auto', "display": "block", "marginTop": "10px" }} />

                </Sidebar>
            </React.Fragment>

        )

    }

    sideBarSelectedBillProduct = () => {
        var productPrice;

        productPrice = this.state.selectedBillProduct.price;
        productPrice = parseFloat(productPrice).toFixed(2);
        return (
            <React.Fragment >

                <Sidebar visible={this.state.showSelectedBillProduct} baseZIndex={1000000} onHide={(e) => this.setState({ showSelectedBillProduct: false })} style={{ "height": "100%" }} >

                    <h1 style={{ fontWeight: 'normal' }}>{this.state.selectedBillProduct.name}</h1>

                    <h2>£{productPrice}</h2>
                    <h2>{this.state.selectedBillProduct.note}</h2>
                    <br /><br /><br /><br />
                    {/* <Button  onClick={() => this.toggleAddNoteMenu(true)} label="Add/Modify Note" /> */}    {/* Disabled as causing a bug - can re-enable later */} 
                    <br /><br />
                    <Button onClick={() => this.deleteProductFromBill(this.state.selectedBillProduct)} label="Remove Product" />
                    <br /><br /><br /><br /><br /><br />
                    <Button type="button" onClick={(e) => this.closeSelectedProductSideBar()} label="Return" className="p-button-success"  />

                </Sidebar>
            </React.Fragment>
        )

    }

    componentDidMount() {
        this.props.loadCategories(this.props.user.token);
        this.props.loadBill(this.props.user.token, this.props.selectedTable.tableID);
        this.props.loading(false);
        if (this.quantityInput) {
            this.quantityInput.select(); // Select text
          }
    }

    tablesList = () => {
        if (this.props.nav !== 'tablesList') {
            this.props.changeNav('tablesList')
        }
    }

    addMenuItems=()=>{
        return (
            <React.Fragment >
                <Button style={{ "position": "absolute", "left": "36.5%", "top": "23px" }} color="primary" variant="contained" onClick={this.tablesList} label={"Tables"}/>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div>
                {this.addMenuItems()}
                <h1 style={{ "margin": "auto", "color": '#3f51b5', "textAlign": "center", "marginTop": "20px" }}>Categories</h1>
                {this.sideBarProducts()}
                {this.sideBarSelectedBillProduct()}
                {this.allCategories()}
                {this.tableBill()}
                {this.finaliseBillMenu()}
                {this.incrementDialog()}
                {this.DecrementDialog()}
                {this.transferSelectedItemsDialogue()}
                {this.addNoteMenu()}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        selectedItem: state.selectedItem,
        categories: state.categories,
        user: state.user,
        selectedTable: state.selectedTable,
        products: state.products,
        bill: state.bill,
        tables: state.tables,           // For drop down list on ""Transfer items to another table"
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({

        loadCategories: loadCategories,
        loading: loading,
        setSelectedItem: setSelectedItem,
        changeNav: changeNav,
        setSelectedTable: setSelectedTable,
        loadProducts: loadProducts,
        unloadProducts: unloadProducts,
        loadBill: loadBill,
        addProduct: addProduct,
        deleteProduct: deleteProduct,
        updateQuantity: updateQuantity,
        updateProduct: updateProduct,
        submitBill: submitBill,
        finaliseBill: finaliseBill,
        getTableBillId: getTableBillId,
        generateTableBillId: generateTableBillId,
        editTable: editTable,
        updateMultipleQuantities: updateMultipleQuantities,
        createMultipleBillItems, createMultipleBillItems,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(ProductBill);
