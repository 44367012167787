import { LOAD_CATEGORIES, LOAD_PRODUCTS } from './types';
import axios from 'axios';
import { expiredLogout, actionError } from './unAuthorised';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function updateCategories(state) {
    return [{
        type: LOAD_CATEGORIES,
        payload: state
    }
    ]
}

function updateProducts(state) {
    return [{
        type: LOAD_PRODUCTS,
        payload: state
    }
    ]
}


export function loadCategories(token) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/categories/`, {
        })
            .then(res => {
                const response = res.data;
                // console.log("\nCategoriesXXXXXXXXXXXX: ");
                // console.log(response)
                if (res.status === 200) {
                    dispatch(updateCategories(response))
                }
            }).catch((e) => {
                if(e.response != null){
                console.log("loadCategories exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
                }
                     dispatch(actionError(e, "load categories"))
                            })
    }
}

export function loadProducts(token, catID) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/categories/` + catID + '/products/', {
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateProducts(response))
                }
            }).catch((e) => {
                if(e.response != null){
                console.log("loadProducts exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
                }
                           
                dispatch(actionError(e, "load products"))
                })
            }
    }

export function unloadProducts() {
    return function (dispatch) {
    dispatch(updateProducts([{"productId":-1,"name":"Loading...","foreground":"ffffff","background":"000000","retailPrice":0.00,"position":null,"available":1}]    ));
}
}






